import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/landingpages/Home';
import Privacy from './pages/Privacy';  // You already used the correct path here

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Define the route for Home page */}
          <Route path="/" element={<Home />} />

          {/* Define the route for Privacy page */}
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

