import React from 'react';
import styles from './detailedservice.module.css';
import SingleService from './SingleService/SingleService';

export default function DetailService() {
  return (
    <div className={`d-flex ${styles.container} ${styles.mb}`}>
      <div className={`d-flex ${styles.detailed_service} ${styles.flex50} ${styles.midBorder}`}>
        <h1>Renter Book</h1>
        <SingleService 
          photo="/serviceImages/rentarbook.png" 
          heading="Dashboard" 
          text="A vendor can manage their business through this app, go to the web order pages to place bids on tenders, and also accept online orders." 
          pic="/arrows/left.jpeg" 
        />
        
        <SingleService 
          photo="/serviceImages/rentar.png" 
          heading="All Tendor" 
          text="On this page, the vendor can view all tenders as well as their own tenders they are currently working on." 
          pic="/arrows/right.jpeg" 
        />
        <SingleService 
          photo="/serviceImages/Bidding.png" 
          heading="Tendor Details / Bid" 
          text="On this page, the vendor can view tender details and place bids." 
          pic="/arrows/right.jpeg" 
        />
         <SingleService 
          photo="/serviceImages/inventory.png" 
          heading="Inventory/Order Management" 
          text="With this app, the vendor can manage inventory, handle orders, and also view order tracking." 
          pic="/arrows/left.jpeg" 
        />
       
        <SingleService 
          photo="/serviceImages/vendor.png" 
          heading="E-Com / Manufacturer" 
          text="On this page, the vendor can purchase new items directly from a manufacturer within the app." 
          pic="/arrows/right.jpeg" 
        />
        
       
        
      </div>
      
      <div className={`d-flex ${styles.detailed_service} ${styles.flex50}`}>
      <h1>Rental Pe</h1>
        <SingleService 
          photo="/serviceImages/dashboard.png" 
          heading="Home Page" 
          text="This page allows the event organizer to login and view all vendors and search vendors." 
          pic="/arrows/left.jpeg" 
        />
        <SingleService 
          photo="/serviceImages/vendordetails.png" 
          heading="Vendor Details" 
          text="This page displays the vendor's portfolio photos, verified portfolio, full description, and contact details." 
          pic="/arrows/right.jpeg" 
        />
        <SingleService 
          photo="/serviceImages/createtendor.png" 
          heading="Create Tendor" 
          text="On this page, the event organizer can create a new tender." 
          pic="/arrows/left.jpeg" 
        />
        <SingleService 
          photo="/serviceImages/alltendor.png" 
          heading="All Tendor's" 
          text="On this page, the event organizer can view all tenders that they have created." 
          pic="/arrows/right.jpeg" 
        />
        <SingleService 
          photo="/serviceImages/bid.png" 
          heading="Bid / Tendor Details" 
          text="On this page, the event organizer can view all bids and select the final vendor who will be working on the tender." 
          pic="/arrows/right.jpeg" 
        />
      </div>
    </div>
  );
}
