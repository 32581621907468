import React from 'react'

const Privacy = () => {
  return (
      <div className="container my-5 pt-4 card bg-white p- rounded shadow">
        <h1 className="mb-3">Privacy Policy</h1>
        <div className="p-4">

        <h2 className="mb-3">Introduction</h2>
        <p>Welcome to Rental Pe  We are committed to safeguarding your privacy. This Privacy Policy outlines how we collect, use, store, and protect your data. By using our services, you agree to the practices described in this policy. (this application is comming soon )</p>

    
        <h2 className="mb-3">Use of Data</h2>
        <p>The data we collect is solely for the purpose of providing and improving our services to you. We do not use your data for any other purposes, including but not limited to marketing, advertising, or any other work unrelated to the services we provide.</p>


        <p>When you log in to your account using our SaaS application (this application is comming soon ), all your account information and data are stored with complete security. Your data is fully protected and your personal information is not used for any other purposes. (this application is cooming soon )</p>

       
        <h2 className="mb-3">Data Retention</h2>
        <p>We retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. Once your data is no longer needed, we will securely delete or anonymize it.</p>

       

        <h2 className="mb-3">Changes to This Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or relevant laws. We will notify you of any significant changes by posting the new Privacy Policy on our website and updating the effective date.</p>

        <h2 className="mb-3">Contact Us</h2>
        <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
        <address>
            Rental Pe<br/>
            
            [Jaipur, Rajasthan, 302017]<br/>
            [voltakeydigital@gmail.com]<br/>
            [+91 80030-06242]
        </address>
        <p>Thank you for trusting Rental Pe. We are dedicated to protecting your privacy and ensuring your data's security.</p>
        </div>

    </div>
  )
}

export default Privacy
